<script setup lang="ts">
import PullToRefresh from "pulltorefreshjs";
import ExternalUser from "@magnit/layer-profile/components/LProfile/Modal/ExternalUser/ExternalUser.vue";
import { OPEN_AUTH_MODAL_ON_INIT_QP } from "@magnit/layer-profile/constants/query-params";

const userStore = useUserStore();
const router = useRouter();

const onExternalLogin = (withReload?: boolean) => {
  if (withReload) {
    window.location.replace(window.location.href.split("?")[0]);
  }
};

const onExternalLogout = () => {
  userStore.logout();
  nextTick(() => {
    router.push({ path: Routes.Login, query: { [OPEN_AUTH_MODAL_ON_INIT_QP]: "1" } });
  });
};

onMounted(() => {
  // window.navigator.standalone есть исключительно в pwa safari
  if (!(window.navigator as Window["navigator"] & { standalone?: boolean; }).standalone) {
    return;
  }
  PullToRefresh.init({
    instructionsPullToRefresh: " ", // пустые строки из-за кривости самой либы
    instructionsReleaseToRefresh: " ",
    instructionsRefreshing: " ",
    iconRefreshing: "&#8635",
    iconArrow: "&#8635;",
  });
});
</script>

<template>
  <NuxtPwaAssets />
  <NuxtLayout>
    <NuxtPage />
    <ExternalUser @login="onExternalLogin" @logout="onExternalLogout" />
  </NuxtLayout>
</template>
