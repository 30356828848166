import { HttpCodes } from "@magnit/layer-api/api";
import { IProfileIncompleteCookieEnum } from "@magnit/layer-api/typings/cookies";

export default defineNuxtRouteMiddleware(async (to, from) => {
  const { isAuthorized } = useAuth({ skipLifeCycleHooks: true });
  const magnitId = useCookie<string>(storage.magnitIDCode);
  const { profileIncompleteState } = useAuthCookies();

  if (
    to.name === String(HttpCodes.NotFound) ||
    to.path === Routes.Landing ||
    to.path === Routes.Chat ||
    to.path === `${Routes.Chat}/` ||
    to.path === Routes.PersonalPromotionsMock ||
    to.path === Routes.CookiePolicy ||
    to.path === `${Routes.CookiePolicy}/` ||
    to.path === Routes.Subscription ||
    to.path === `${Routes.Subscription}/` ||
    to.path === Routes.ProfileDelete ||
    to.path === `${Routes.ProfileDelete}/`
  ) {
    return;
  }

  if (!isAuthorized.value) {
    if (magnitId.value && to.path === Routes.Registration) {
      return;
    }

    if (to.path.includes(Routes.PaymentMethods)) {
      return navigateTo({
        path: Routes.Login,
        query: { redirect: Routes.PaymentMethodsPay },
      });
    }

    /*
     * исключение для внешнего перехода с кросс-токеном на защищиенную страницу при
     * отсутствующей авторизации пользователя, чтобы не происходил редирект на страницу логина
     * с потерей токена
     * TODO заменить имя параметра на импортированную из ДС константу
     */
    if (to.query["cross_token"]) {
      return;
    }

    if (to.path !== Routes.Login) {
      return navigateTo(Routes.Login);
    }
  } else {
    if (
      (profileIncompleteState.value === IProfileIncompleteCookieEnum.incomplete) &&
      ![Routes.ProfileIncomplete, Routes.Registration].includes(to.path as Routes)
    ) {
      return navigateTo(Routes.ProfileIncomplete);
    }

    if (to.path === Routes.RegistrationPush && from.path !== Routes.Registration) {
      return navigateTo(Routes.Main);
    }

    if (to.path === Routes.Login || to.path === Routes.Registration) {
      return navigateTo(Routes.Main);
    }
  }

  /**
   * Редиректы со старых адресов
   */

  if (
    [
      RedirectedRoutes.App,
      RedirectedRoutes.FullEnrollment,
      RedirectedRoutes.Promo,
      RedirectedRoutes.Dashboard,
      RedirectedRoutes.Enrollment,
    ]
      .map((r) => r.replace("/", ""))
      .includes(to.path.replaceAll("/", ""))
  ) {
    return navigateTo(Routes.Main, { redirectCode: 301 });
  }
  if (
    [RedirectedRoutes.Terms].map((r) => r.replace("/", "")).includes(to.path.replaceAll("/", ""))
  ) {
    return navigateTo(ExternalRoutes.MagnitPromopokarte, {
      external: true,
    });
  }
  if (
    [RedirectedRoutes.Offers].map((r) => r.replace("/", "")).includes(to.path.replaceAll("/", ""))
  ) {
    return navigateTo(Routes.ProfilePersonalPromotions, { redirectCode: 301 });
  }
  if (
    [RedirectedRoutes.MemberProfile]
      .map((r) => r.replace("/", ""))
      .includes(to.path.replaceAll("/", ""))
  ) {
    return navigateTo(`${Routes.Profile}#tab=1`, { redirectCode: 301 });
  }
  if (
    [RedirectedRoutes.Transactions]
      .map((r) => r.replace("/", ""))
      .includes(to.path.replaceAll("/", ""))
  ) {
    return navigateTo(Routes.ProfileTransactions, { redirectCode: 301 });
  }
});
