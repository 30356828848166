
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as qriFwY53gpX8JaojRsZXUz7bIZeCmNnLJDX0zMZ2y2GAkMeta } from "/app/src/pages/qr.vue?macro=true";
import { default as indexiNtA_znKj_zn_45wsEgsmLpyYSVS7XVZ24_KIDRjqK_10Meta } from "/app/src/pages/index.vue?macro=true";
import { default as loginat_45I4W6dhaKeU4e7R3nRygvy7k1_45w8CiYRML3dJJNP8Meta } from "/app/src/pages/login.vue?macro=true";
import { default as today5Eba34wFDsLPsZjA6Q2vzAfxDLyY5dralCbl1LmYCrwMeta } from "/app/src/pages/today.vue?macro=true";
import { default as editNIx1qlmfAQvJT_JtWX7Kd2hq5piAqmlPtAyk0EBAQNAMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile/edit.vue?macro=true";
import { default as addbhjT2wD6Wnpn_71rjZrX_kZxgqdsy7o4wVFgG8BDqkQMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile/cards/add.vue?macro=true";
import { default as indexKdoVLXGrkQWn_4hpx_qUMxu34cRTj3tGb8GEybHMUiIMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile/cards/index.vue?macro=true";
import { default as cards7trRGZwW9jyRU_yvnGce1jMHkOAJHv2KC09e55EWsjEMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile/cards.vue?macro=true";
import { default as indexIRFpxQLo1WYQ1Ms3F1CYqzJsVtil4aAMr_45A0lYFJttkMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile/index.vue?macro=true";
import { default as mplusnogwYCOif60hp7b5BdqucwvpfH_R47d3nFZSu25munwMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile/mplus.vue?macro=true";
import { default as _91id_93ATAZhLY_sn0U02gGUXQgMecJZpm7PnlghPya6SV3nBcMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile/orders/[id].vue?macro=true";
import { default as indexehh7JeKiuoY9sAbZIBZ6GMlb_45UBct4kM_BLICZocSmgMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile/orders/index.vue?macro=true";
import { default as ordersLwADKH_f3sZMNqKK4TkxciPTfJDenIIY_45qS0x3DuRqMMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile/orders.vue?macro=true";
import { default as indexX7_45n06zSATXDwohlJoLuCpviz_45zCYYjEwSEFWmwUIlkMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile/coupons/index.vue?macro=true";
import { default as _91couponId_93XglMUQqkB95hGxJRLykbEUVs_VkRLldOAZgHYVSrlesMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile/coupons/[couponId].vue?macro=true";
import { default as couponsVeOkw_9Q2U6yOYVMPsUXXwV5tfueCvjDE_b_vqWo3JgMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile/coupons.vue?macro=true";
import { default as supportoQxJalmz9YuM5HtLl1x79LvHuCxAObeT9nK3uhKhqCkMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile/support.vue?macro=true";
import { default as stickersSggqfemmXBLTP8SGKiD62tlDOR5zB2a1UgHp0BXV_45jEMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile/stickers.vue?macro=true";
import { default as favoritesDiJ_4595EgcAfFHJ0CkjvceFg9MalPHjbyvhUKU5_OY0cMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile/favorites.vue?macro=true";
import { default as incompletemvp0d0QlM0XnpZ1yIMjtAG9JipX1khg_45OomdFpubykoMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile/incomplete.vue?macro=true";
import { default as notificationpchO5eiizDdDkibFi49k2cCUp9Tu99JMA7UEFPBGPzUMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile/notification.vue?macro=true";
import { default as _91id_93FN9bzE3WBL7JsmbR_45tFfls39zjU5o5Z2Ydv2hjgTZwAMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile/transactions/[id].vue?macro=true";
import { default as index3FajZuih9_n3yha86m63wGFa5pNdodsF2qz_7nY4obIMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile/transactions/index.vue?macro=true";
import { default as transactionsb2vGI9_45QqIw4CDI80LNqD6ZV6nadOjfb99iPgIklUVsMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile/transactions.vue?macro=true";
import { default as indexrwwbL90yAMWn4QvACj___GLMlE51uHrn6CLC4OAVungMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile/payment-cards/index.vue?macro=true";
import { default as changenXYY0AEDTtadhOVfcchI2bTncQQTbL3NNQik3d8mYmoMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile/payment-cards/change.vue?macro=true";
import { default as payment_45cards8ejggEi2yz_45zgA_454pMR4FjNR1CdbngiycCGSZqMEKkkMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile/payment-cards.vue?macro=true";
import { default as push_45instructionsXoVCyOV9e1GuLQFDVhOnO9jb67nAwAquctIRdlm7rJcMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile/push-instructions.vue?macro=true";
import { default as favorite_45categoriesgFD5gIW_45w3BcxyDRpDpwl7bPIsRN4iwOI1EVMAGwbW8Meta } from "/app/node_modules/@magnit/layer-profile/pages/profile/favorite-categories.vue?macro=true";
import { default as pay5cGwlK4bTzF3IYTEHiSRpdZ5QhSTMfd1Ke0qr6yaRXsMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile/payment-methods/pay.vue?macro=true";
import { default as indexxc8TLAF8EXRlKP4UYz5QqOG1E1jIVWFPEW0Mzua97bwMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile/personal-promotions/index.vue?macro=true";
import { default as _91promoId_93eFbk0H_45NGE0O0fHZVJq2sh3o_450MQQlqmnj_45bUGL71A8Meta } from "/app/node_modules/@magnit/layer-profile/pages/profile/personal-promotions/[promoId].vue?macro=true";
import { default as personal_45promotionsixSp3IcY_Rm_lMOHrZ9hnYHQ84ua7JGeXJnNnOmLb3AMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile/personal-promotions.vue?macro=true";
import { default as deleteKxpGNsgWU6XGuPCMmbIFowyrKyNT45GeOP1McU8TuSEMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile/payment-methods/delete.vue?macro=true";
import { default as profileYXVGauHWL51aEIU7UymcMmYU_uNg15kYC_fanU_45nMVUMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile.vue?macro=true";
import { default as pushtokenQMxDzALGRVNIitvrbzvyjDuki_FDIOiMSJzHAodpGnIMeta } from "/app/src/pages/pushtoken.vue?macro=true";
import { default as cross_45token_45ltlry8bnRTgb5iVk_Kestdm0z0twsF_45rhgrvtX_Hm0Meta } from "/app/src/pages/cross-token.vue?macro=true";
import { default as subscriptiontjI3Kqr3vzIpx4335JfBm5xTtaxzdcUxvN44WQxdb8EMeta } from "/app/src/pages/subscription.vue?macro=true";
import { default as cookie_45policyJGFq3o4u1xTPQn_0GB7HpO9BWnIj8wkgjhzm5cVcyD8Meta } from "/app/src/pages/cookie-policy.vue?macro=true";
import { default as delete_accountr756viTbKpRreCR48aNWZPKjXON5_45WTLv9Xiom9ejccMeta } from "/app/src/pages/delete_account.vue?macro=true";
import { default as pushNy1SQ42ePU1WAcFKMxN5JUi9SXpAK9L_45h7zV66DsOJYMeta } from "/app/src/pages/registration/push.vue?macro=true";
import { default as indexaXET_C8ge14dK_Ls0DxrDFrMs7dvNMxSxP8SayQloxUMeta } from "/app/src/pages/registration/index.vue?macro=true";
export default [
  {
    name: "qr",
    path: "/qr",
    component: () => import("/app/src/pages/qr.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/src/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginat_45I4W6dhaKeU4e7R3nRygvy7k1_45w8CiYRML3dJJNP8Meta || {},
    component: () => import("/app/src/pages/login.vue")
  },
  {
    name: "today",
    path: "/today",
    component: () => import("/app/src/pages/today.vue")
  },
  {
    name: profileYXVGauHWL51aEIU7UymcMmYU_uNg15kYC_fanU_45nMVUMeta?.name,
    path: "/profile",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile.vue"),
    children: [
  {
    name: "profile-edit",
    path: "edit",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/edit.vue")
  },
  {
    name: cards7trRGZwW9jyRU_yvnGce1jMHkOAJHv2KC09e55EWsjEMeta?.name,
    path: "cards",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/cards.vue"),
    children: [
  {
    name: "profile-cards-add",
    path: "add",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/cards/add.vue")
  },
  {
    name: "profile-cards",
    path: "",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/cards/index.vue")
  }
]
  },
  {
    name: "profile",
    path: "",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/index.vue")
  },
  {
    name: "profile-mplus",
    path: "mplus",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/mplus.vue")
  },
  {
    name: ordersLwADKH_f3sZMNqKK4TkxciPTfJDenIIY_45qS0x3DuRqMMeta?.name,
    path: "orders",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/orders.vue"),
    children: [
  {
    name: "profile-orders-id",
    path: ":id()",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/orders/[id].vue")
  },
  {
    name: "profile-orders",
    path: "",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/orders/index.vue")
  }
]
  },
  {
    name: couponsVeOkw_9Q2U6yOYVMPsUXXwV5tfueCvjDE_b_vqWo3JgMeta?.name,
    path: "coupons",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/coupons.vue"),
    children: [
  {
    name: "profile-coupons",
    path: "",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/coupons/index.vue")
  },
  {
    name: "profile-coupons-couponId",
    path: ":couponId()",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/coupons/[couponId].vue")
  }
]
  },
  {
    name: "profile-support",
    path: "support",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/support.vue")
  },
  {
    name: "profile-stickers",
    path: "stickers",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/stickers.vue")
  },
  {
    name: "profile-favorites",
    path: "favorites",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/favorites.vue")
  },
  {
    name: "profile-incomplete",
    path: "incomplete",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/incomplete.vue")
  },
  {
    name: "profile-notification",
    path: "notification",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/notification.vue")
  },
  {
    name: transactionsb2vGI9_45QqIw4CDI80LNqD6ZV6nadOjfb99iPgIklUVsMeta?.name,
    path: "transactions",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/transactions.vue"),
    children: [
  {
    name: "profile-transactions-id",
    path: ":id()",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/transactions/[id].vue")
  },
  {
    name: "profile-transactions",
    path: "",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/transactions/index.vue")
  }
]
  },
  {
    name: payment_45cards8ejggEi2yz_45zgA_454pMR4FjNR1CdbngiycCGSZqMEKkkMeta?.name,
    path: "payment-cards",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/payment-cards.vue"),
    children: [
  {
    name: "profile-payment-cards",
    path: "",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/payment-cards/index.vue")
  },
  {
    name: "profile-payment-cards-change",
    path: "change",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/payment-cards/change.vue")
  }
]
  },
  {
    name: "profile-push-instructions",
    path: "push-instructions",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/push-instructions.vue")
  },
  {
    name: "profile-favorite-categories",
    path: "favorite-categories",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/favorite-categories.vue")
  },
  {
    name: "profile-payment-methods-pay",
    path: "payment-methods/pay",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/payment-methods/pay.vue")
  },
  {
    name: personal_45promotionsixSp3IcY_Rm_lMOHrZ9hnYHQ84ua7JGeXJnNnOmLb3AMeta?.name,
    path: "personal-promotions",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/personal-promotions.vue"),
    children: [
  {
    name: "profile-personal-promotions",
    path: "",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/personal-promotions/index.vue")
  },
  {
    name: "profile-personal-promotions-promoId",
    path: ":promoId()",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/personal-promotions/[promoId].vue")
  }
]
  },
  {
    name: "profile-payment-methods-delete",
    path: "payment-methods/delete",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/payment-methods/delete.vue")
  }
]
  },
  {
    name: "pushtoken",
    path: "/pushtoken",
    component: () => import("/app/src/pages/pushtoken.vue")
  },
  {
    name: "cross-token",
    path: "/cross-token",
    component: () => import("/app/src/pages/cross-token.vue")
  },
  {
    name: "subscription",
    path: "/subscription",
    meta: subscriptiontjI3Kqr3vzIpx4335JfBm5xTtaxzdcUxvN44WQxdb8EMeta || {},
    component: () => import("/app/src/pages/subscription.vue")
  },
  {
    name: "cookie-policy",
    path: "/cookie-policy",
    component: () => import("/app/src/pages/cookie-policy.vue")
  },
  {
    name: "delete_account",
    path: "/delete_account",
    component: () => import("/app/src/pages/delete_account.vue")
  },
  {
    name: "registration-push",
    path: "/registration/push",
    component: () => import("/app/src/pages/registration/push.vue")
  },
  {
    name: "registration",
    path: "/registration",
    component: () => import("/app/src/pages/registration/index.vue")
  }
]